import find from "lodash/find";
import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";

import removeDuplicateObjectsFromArray from "./removeDuplicateObjectsFromArray";

export function configurationHelper(data) {
  function getRegions() {
    if (!data?.regions) {
      return [];
    }

    return data.regions.map((region) => ({
      name: region.name,
      id: region.id,
      zone_id: region.zone_id,
      region_id: region.id,
    }));
  }

  /**
   * Get data object for region.
   *
   * @param name string Server Location value
   * @returns array|undefined
   */
  function getDataByRegion(name = "lan") {
    if (!data?.regions) {
      return {};
    }
    const regionData = find(data.regions, (o) => o.name === name);

    // Check for and remove duplicate management objects
    if (regionData) {
      return {
        ...regionData,
        management: removeDuplicateObjectsFromArray(regionData.management, 'name'), 
      };
    }

    return {};
  }

  /**
   * @todo Need to update this to draw from active region
   */
  function getOperatingSystems() {
    if (!data?.os) {
      return [];
    }
    return data.os;
  }

  /**
   * Get control panel options for region.
   *
   * @param name string Server Location value
   * @returns array
   */
  function getControlPanelOptionsByRegion(location = "lan") {
    if (!data?.regions) {
      return [];
    }

    const { control_panels } = getDataByRegion(location);

    return control_panels ? control_panels : [];
  }

  /**
   * Get available control panels
   * @return array
   */
  function getAvailableControlPanelOptions() {
    if (!data?.control_panels) {
      return [];
    }

    return removeDuplicateObjectsFromArray(data.control_panels, 'type');
  }

  function getHardwareOptionsByRegion(location = "lan") {
    if (!data?.regions) {
      return {};
    }

    const { configs } = getDataByRegion(location);

    return configs ? configs : [];
  }

  /**
   * Return management options by region with available OS.
   *
   * @param name string Server Location value
   * @returns object
   */
  function getManagementOptionsByRegion(location = "lan") {
    if (!data?.regions) {
      return {};
    }

    const { management } = getDataByRegion(location);
    const orderedManagement = sortByKey(management, "display_order");

    if (orderedManagement.length) {
      let levels = {};

      orderedManagement.forEach((level) => {
        const orderedOs = sortByKey(level.os, "display_order");
        // Remove duplicte control_panels from data
        const cleanedOs = orderedOs.map((os) => ({
          ...os,
          control_panels: removeDuplicateObjectsFromArray(os.control_panels, 'type'),
        }));
        const groupedOs = groupBy(cleanedOs, (o) => o.name);
        
        levels[level.name] = groupedOs;
      });

      return levels;
    }

    return {};
  }

  /**
   * Get operating system distros
   */
  function getOperatingSystemDistros() {
    if (!data?.os) {
      return [];
    }
    const osNames = data.os.map((os) => os.name);

    return uniq(osNames.filter((image) => !image.includes("App:")));
  }
  
  /**
   * Get application distros
   */
  function getApplicationDistros() {
    if (!data?.os) {
      return [];
    }
    const osNames = data.os.map((os) => os.name);

    return uniq(osNames.filter((image) => image.includes("App:")));
  }

  /**
   * Sort array of objects by key
   */
  function sortByKey(array, key) {
    if (!Array.isArray(array) || !key) {
      return [];
    }

    return [...array].sort((a, b) => {
      const x = a[key];
      const y = b[key];

      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  /**
   * Return configuration object for location
   */
  function getRegionConfigByName(name = "lan") {
    if (!data?.regions) {
      return [];
    }
    const match = data.regions.filter((region) => region.name === name);

    return match.length ? match[0] : [];
  }

  /**
   * Get the configuration data based on configuration id
   */
  function getConfigsById(id = []) {
    if (!data) {
      return [];
    }
    const { configs } = data;

    if (Array.isArray(id) && id.length) {
      return configs.filter((config) => {
        return id.includes(config.id);
      });
    }

    if (typeof id === "number") {
      return configs.filter((config) => config.id === id);
    }

    return [];
  }

  function hasSubscriptionTerms() {
    return data?.cycles && data.cycles.length > 1;
  }

  return {
    getRegions,
    getDataByRegion,
    getManagementOptionsByRegion,
    getOperatingSystemDistros,
    getApplicationDistros,
    getControlPanelOptionsByRegion,
    getAvailableControlPanelOptions,
    getHardwareOptionsByRegion,
    getOperatingSystems,
    getRegionConfigByName,
    getConfigsById,
    sortByKey,
    hasSubscriptionTerms,
  };
}
