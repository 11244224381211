import classnames from "classnames";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

import config from "../../../config";

import { useAddToCart } from "../../../hooks/useAddToCart";
import { useSubscriptionPricing } from "../../../hooks/useSubscriptionPricing";
import { useAppState } from "../../../state";
import actions from "../../../state/configuration/actions";
import { removeDecimalIfWhole } from "../../../utils/removeDecimalIfWhole";

import { SkeletonCard } from "../common/skeleton-card";
import { CART_COOKIE_NAME, ERROR_API_POST } from "../constants";
import { QuantityControl } from "../quantity-control";

import ProductLinkReference from "../product-link-reference/ProductLinkReference";

const SERVER_QTY_LABEL = "Servers Quantity";
const BASE_PRODUCT_PAYLOAD = {
  package_code: null,
  region_id: null,
  zone_id: null,
  config_id: null,
  template: null,
  ddos: null,
  extra_ip: null,
  use_ipv6: 0,
  quantity: 1,
  backups: {
    name: "per_gb",
  },
  block_storage: {
    name: "none",
  },
  package_version_id: null,
};

const BASE_PACKAGE_PAYLOAD = {
  quantity: 1,
  package_code: null,
  template: null,
  region_id: null,
  config_id: null,
  ddos: null,
  package_version_id: null,
};

const MonthlyPricing = ({ cost }) => (
  <div className="flex items-center gap-1 text-base lg:text-xl">
    <span>{`$${cost}`}</span>
    <span className="font-light">Monthly</span>
  </div>
);

const TermPricing = ({ costFull, costToday, discount }) => {
  return (
    <div className="flex flex-col sm:flex-row items-end sm:items-center sm:gap-4 lg:gap-6">
      <span className="flex items-center gap-1 text-xs sm:text-base max-sm:leading-none">
        <del className="font-light text-sm lg:text-xl text-lw-text-disabled">
          {`$${costFull}`}
        </del>
        <span className="font-medium text-lw-ui-green">{`(-${discount}%)`}</span>
      </span>
      <span className="flex items-center gap-1 sm:gap-2 text-sm sm:text-base lg:text-xl">
        <span className="font-bold text-lw-ui-green">{`$${costToday}`}</span>
        <span className="font-light">Due Today</span>
      </span>
    </div>
  );
};

export function FloatingCart() {
  const [{ configuration: configState, summary: summaryState }, dispatch] =
    useAppState();
  const { fullCost, singleCost, discountedCost, discount } = useSubscriptionPricing();
  const { addToCart } = useAddToCart();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isWindows = configState.operatingSystemType === "windows";
  const isCpanel = summaryState?.controlPanel?.value === "cPanel";
  const isDisabled =
    configState.isLoading ||
    configState.isError ||
    addToCart.isLoading ||
    isSubmitting ||
    configState.templateValue === "";

  // Reset state and mutation on unmount
  useEffect(() => {
    return () => {
      setIsSubmitting(false);
      addToCart.reset();
    }
  }, []);

  useEffect(() => {
    if (addToCart.status === "success" && addToCart?.data?.cart_uuid) {
      Cookies.set(CART_COOKIE_NAME, addToCart.data.cart_uuid, {
        domain: config.cart.cookieDomain,
        path: "/",
      });

      // Reset mutation
      addToCart.reset();

      window.location.assign(config.cart.redirectURL);
    }

    if (addToCart.isError || addToCart?.data?.errors?.length) {
      dispatch(actions.setIsError("api-post"));
      dispatch(actions.setErrorMessage(ERROR_API_POST));
    }
  }, [addToCart.status, addToCart.data]);

  function prepareProductData() {
    const blockStorage =
      configState.blockStorage === 0
        ? { name: "none" }
        : { name: "per_gb", gb: String(configState.blockStorage) };
    const backups = configState.backupsEnabled
      ? { name: "per_gb" }
      : { name: "none" };
    const extraIp =
      typeof configState.ipAddresses === "number"
        ? configState.ipAddresses - 1
        : 0;
    const cPanelLicense =
      !isWindows && isCpanel
        ? { cpanel_license: configState.cPanelLicenseTier }
        : null;
    const windowsAddOns = isWindows
      ? {
        win_av: configState.windowsAntiVirus,
        mssql: configState.windowsMsSQL,
      }
      : null;

    return Object.assign(
      {},
      BASE_PRODUCT_PAYLOAD,
      {
        quantity: configState.serverQty,
        package_code: configState.productCode,
        template: configState.templateValue,
        region_id: Number(configState.serverRegionId),
        zone_id: Number(configState.serverZoneId),
        ddos: configState.ddosOption,
        config_id: configState.hardwareOption,
        extra_ip: extraIp,
        use_ipv6: configState.addIPv6 ? 1 : 0,
        block_storage: blockStorage,
        package_version_id: configState.packageVersionId,
        cycle: configState?.subscriptionCycle?.cycle
          ? configState.subscriptionCycle.cycle
          : "monthly",
        backups,
      },
      cPanelLicense,
      windowsAddOns,
    );
  }

  function preparePackageData() {
    return Object.assign({}, BASE_PACKAGE_PAYLOAD, {
      quantity: configState.serverQty,
      package_code: configState.productCode,
      template: configState.templateValue,
      region_id: Number(configState.serverRegionId),
      config_id: configState.hardwareOption,
      ddos: configState.ddosOption,
      package_version_id: configState.packageVersionId,
      cycle: configState?.subscriptionCycle?.cycle
        ? configState.subscriptionCycle.cycle
        : "monthly",
    });
  }

  function sendToGoogleTagManager(
    total_cost,
    product_code,
    product_cost,
    product_qty,
  ) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "lw.ecommerce",
      ecommerceType: "ga4",
      data: {
        category: "Ecommerce",
        action: "basketItem_added",
      },
      ecommerceEventName: "add_to_cart",
      ecommerce: {
        currency: "USD",
        value: total_cost ? String(total_cost) : "",
        items: [
          {
            item_name: product_code,
            price: product_cost ? String(product_cost) : "",
            quantity: product_qty ? String(product_qty) : "",
            item_brand: "Liquid Web",
          },
        ],
      },
    });
  }

  function getPricingPartial() {
    const { subscriptionCycle: { cycle } } = configState;

    if (configState.isLoading || configState.isError === "api-fetch") {
      return;
    }

    if (cycle !== "monthly") {
      return (
        <TermPricing
          costFull={removeDecimalIfWhole(fullCost)}
          costToday={removeDecimalIfWhole(discountedCost)}
          discount={discount}
        />
      );
    }

    return <MonthlyPricing cost={removeDecimalIfWhole(fullCost)} />;
  }

  function handlePurchaseClick() {
    const preparedData = configState.isBareMetal
      ? preparePackageData()
      : prepareProductData();
    addToCart.mutate(preparedData);
    setIsSubmitting(true);

    sendToGoogleTagManager(
      discountedCost,
      configState.productCode,
      singleCost,
      configState.serverQty,
    );
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 w-full">
      <div className="mx-auto w-full lg:px-6">
        <div className="lg:mb-6 lg:mx-auto max-w-[2188px] lg:rounded-md flex items-center justify-between gap-4 bg-white lg:shadow-metal border border-t-lw-ui-border lg:border-0 px-4 lg:pl-6 lg:pr-2 py-2">
          {configState.isLoading || configState.isError === "api-fetch" ? (
            <div className="flex items-center">
              <span className="text-sm font-medium mr-4 hidden lg:block">
                {SERVER_QTY_LABEL}
              </span>
              <SkeletonCard className="w-[172px] h-[50px]" />
            </div>
          ) : (
            <QuantityControl
              inputWrapperElemClasses="!text-xl"
              labelElemClasses="mr-4 hidden lg:block"
              size="sm"
              label={SERVER_QTY_LABEL}
              value={configState.serverQty}
              min={1}
              max={configState.isBareMetal ? 3 : 10}
              onChange={(value) => dispatch(actions.setServerQty(value))}
            />
          )}
          <ProductLinkReference />
          <div className="flex items-center gap-4 lg:gap-6">
            {getPricingPartial()}
            <button
              className={classnames(
                "px-3",
                "sm:px-4",
                "py-2",
                "lg:px-7",
                "lg:py-3",
                "rounded",
                "bg-lw-ui-green",
                "hover:bg-lw-ui-green-dark",
                "text-white",
                "text-sm",
                "transition-all",
                {
                  "!bg-lw-disabled": isDisabled,
                  "!text-lw-text-disabled": isDisabled,
                },
              )}
              onClick={handlePurchaseClick}
              disabled={isDisabled ? true : null}
            >
              {configState.isLoading || addToCart.isLoading || isSubmitting
                ? "Loading..."
                : "Buy & Deploy"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
