import { find, isPlainObject, minBy, uniq } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useProductDetails } from "../../../hooks/useProductDetails";
import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";
import { configurationHelper } from "../../../utils/configurationHelper";
import { getTemplateAsset } from "../../../utils/getTemplateAsset";

import { ButtonPill } from "../common/button/ButtonPill";
import { Select } from "../common/select";
import { SelectableCard } from "../common/selectable-card";
import { SkeletonCard } from "../common/skeleton-card";

import { OS_TABS, SERVER_TYPE_GPU } from "../constants";

export function SectionTemplate() {
  const cardRef = useRef({});
  const [defaultDistro, setDefaultDistro] = useState({
    id: "",
    title: "",
    subTitle: "",
    summaryName: "",
    distro: "",
    version: "",
    bit: "",
    template: "",
    controlPanel: "NoCP",
  });
  const [hasAppTemplates, setHasAppTemplates] = useState(false);
  const [{ configuration: configState }, dispatch] = useAppState();
  const [searchParams] = useSearchParams();

  const productData = useProductDetails();
  const { data } = productData[configState.productCode];

  const { sortByKey, getApplicationDistros, getOperatingSystemDistros } =
    configurationHelper(data);
  const showSkeleton =
    configState.isLoading ||
    configState.isError === "api-fetch" ||
    configState.isError === "management";

  const distros = useMemo(() => {
    const availableDistros =
      configState?.availableDistros &&
        Object.keys(configState.availableDistros).length
        ? configState.availableDistros
        : false;
    const osDistros = getOperatingSystemDistros(configState.serverLocation);
    const appDistros = getApplicationDistros(configState.serverLocation);
    const nextDistros = [];

    setHasAppTemplates(appDistros.length);

    if (availableDistros) {
      const distroKeys = Object.keys(availableDistros);

      if (configState.operatingSystemTab === "app" && appDistros.length) {
        distroKeys.forEach((key) => {
          if (appDistros.includes(key)) {
            const distro = prepareDistroData(key, availableDistros, true);
            if (distro) {
              nextDistros.push(distro);
            }
          }
        });
      } else {
        distroKeys.forEach((key) => {
          if (osDistros.includes(key)) {
            const distro = prepareDistroData(key, availableDistros);
            if (distro) {
              nextDistros.push(distro);
            }
          }
        });
      }

      // Return early if nextDistros is empty
      if (nextDistros.length === 0) {
        return [];
      }

      // If the default distro is not present in the current collection of nextDistros, use the first from that grouping
      const nextDefaultDistro = find(nextDistros, { default: true })
        ? find(nextDistros, { default: true })
        : nextDistros[0];

      const nextDefaultDistroVersion = isPlainObject(
        nextDefaultDistro?.versions,
      )
        ? nextDefaultDistro.versions.value
        : find(nextDefaultDistro?.versions, { default: true })
          ? find(nextDefaultDistro.versions, { default: true }).value
          : nextDefaultDistro.versions[0].value;

      // Set next default distro
      setDefaultDistro({
        id: nextDefaultDistro.id,
        title: nextDefaultDistro.title,
        subTitle: nextDefaultDistro.subTitle,
        summaryName: nextDefaultDistro.summaryName,
        distro: nextDefaultDistro.name,
        version: nextDefaultDistroVersion,
        bit: nextDefaultDistro?.bit ? `${nextDefaultDistro.bit}-bit` : "",
        template: nextDefaultDistro?.template,
        controlPanel: nextDefaultDistro.controlPanel,
      });
    }

    return nextDistros;
  }, [
    configState.availableDistros,
    configState.managementLevel,
    configState.operatingSystemTab,
    configState.operatingSystemType,
    configState.serverType,
    configState.serverLocation,
  ]);

  useEffect(() => {
    const defaultTab = searchParams.get("itab");
    // Pre-select OS tab for query param
    dispatch(configActions.setOperatingSystemTab(defaultTab));
  }, []);

  useEffect(() => {
    const isApp = configState.operatingSystemTab === 'app';
    const title = configState.operatingSystemTitle;
    const subTitle = configState.operatingSystemSubTitle;
    const version = configState.operatingSystemVersion;

    // Update summary on template value change.
    dispatch(
      summaryActions.setDistro({
        value: isApp ? `${title} ${subTitle}` : `${title} ${version} ${subTitle}`,
      }),
    );
  }, [configState.templateValue]);

  useEffect(() => {
    if (!searchParams.has("itab")) {
      // Pre-select OS tab
      dispatch(configActions.setOperatingSystemTab(SERVER_TYPE_GPU === configState.serverType ? 'app' : 'os'));
    }
  }, [configState.serverType]);

  useEffect(() => {
    if (distros) {
      const { distro, version, template, controlPanel, title, subTitle } = defaultDistro;

      if (distro && version) {
        dispatch(configActions.setOperatingSystemDistro(distro));
        dispatch(configActions.setOperatingSystemVersion(version));
        dispatch(configActions.setOperatingSystemTitle(title));
        dispatch(configActions.setOperatingSystemSubTitle(subTitle));
        dispatch(
          configActions.setOperatingSystemVersionValue(
            `${template}:${version}`,
          ),
        );
        dispatch(configActions.setControlPanel(controlPanel));
        dispatch(configActions.setTemplateValue(template));
      }
    }
  }, [
    defaultDistro,
    distros,
    configState.operatingSystemTab,
    configState.operatingSystemType,
  ]);

  function prepareDistroData(distroKey, allDistros, isApp = false) {
    if (!distroKey || allDistros.length === 0) {
      return;
    }

    const versions = getAvailableDistributionVersions(distroKey, allDistros);
    const hasMultipleVersions = Array.isArray(versions) && versions.length > 1;
    const defaultDistro = find(allDistros[distroKey], { default: 1 });
    const defaultDistroVersion = find(versions, { default: true, }) || minBy(versions, 'order');
    const nextDistro = defaultDistro || allDistros[distroKey][0];
    const defaultCP = find(nextDistro.control_panels, { default: 1 }) || minBy(nextDistro.control_panels, 'display_order' );

    let id = distroKey;
    let name = distroKey;
    let bit = nextDistro?.bit ? `${nextDistro.bit}-bit` : '';
    let title = !hasMultipleVersions ? `${distroKey} ${defaultDistroVersion.value}` : distroKey;
    let summaryName = `${title}${bit ? ` ${bit}` : ''}`;
    let subTitle = bit;

    // App-specific values
    if (isApp) {
      const [distroName, appName] = distroKey
        .replace(/\[|\]/g, "")
        .split("App:");

      id =
        configState.serverType === SERVER_TYPE_GPU ? `GPU ${appName}` : appName;
      name = appName;
      bit = `on ${distroName.trim()} ${defaultDistroVersion.value}`;
      summaryName = `${appName} ${bit}`;
      title = appName;
      subTitle = bit;
    }

    return {
      id,
      name,
      summaryName,
      title,
      subTitle,
      bit,
      versions: versions.length > 1 ? versions : versions[0],
      template: defaultCP?.template ? defaultCP.template : '',
      controlPanel: defaultCP?.name ? defaultCP.name : 'NoCP',
      default: !!defaultDistro,
    };
  }

  /**
   * Get all versions for a given distribution
   * @returns array
   */
  function getAvailableDistributionVersions(distroKey, allDistros = []) {
    if (!distroKey) {
      return [];
    }

    const versions = [];

    allDistros[distroKey].forEach((distro) => {
      const templateValue = getDistroTemplateValue(distro);
      const controlPanelValue = getDistroControlPanelValue(distro);

      if (distro.version && distro.version !== "") {
        versions.push({
          order: distro.display_order,
          value: distro.version,
          default: distro.default === 1 ? true : false,
          template: templateValue,
          controlPanel: controlPanelValue,
        });
      }
    });

    return uniq(versions).sort((a, b) => b.order + a.order);
  }

  function getDistroVersionSelectField(os) {
    const { versions } = os;

    if (!Array.isArray(versions) || versions.length <= 0) {
      return (
        <input type="hidden" value={`${versions.template}:${versions.value}`} />
      );
    }

    const sortedVersions = sortByKey(versions, "order");

    return (
      <div className="xl:mr-4">
        <Select
          label={<label className="text-xs mr-2">Version</label>}
          controlElemClass="!flex-row items-center"
          selectWrapElemClass="!w-[92px] sm:!w-[160px]"
          size="sm"
          onClick={(event) => event.stopPropagation()}
          onChange={() =>
            handleDistributionClick(cardRef.current[os.id], os)
          }
          value={configState.operatingSystemVersionValue}
        >
          {sortedVersions.map((version, index) => (
            <option
              key={`${index}-${version.value}`}
              value={`${version.template}:${version.value}`}
            >
              {version.value}
            </option>
          ))}
        </Select>
      </div>
    );
  }

  /**
   * Get the distro's template, based on selected control panel
   * @returns string
   */
  function getDistroTemplateValue(distro) {
    if (!distro || !Array.isArray(distro?.control_panels)) {
      return "";
    }

    const templateValue = find(distro.control_panels, {
      type: configState.controlPanel,
    });

    return templateValue && templateValue?.template
      ? templateValue.template
      : "";
  }

  function getDistroControlPanelValue(distro) {
    if (!distro || !Array.isArray(distro?.control_panels)) {
      return "";
    }

    const next =
      find(distro.control_panels, { default: 1 }) || distro.control_panels[0];

    return next?.type ? next.type : "";
  }

  function handleOperatingSystemTabClick(tab) {
    dispatch(configActions.setOperatingSystemTab(tab));
  }

  function handleDistributionClick(ref, distro) {
    const version = ref.current.querySelector("input")
      ? ref.current.querySelector("input").value
      : ref.current.querySelector("select").value;
    const [templateValue, versionValue] = version.split(":");

    dispatch(configActions.setOperatingSystemVersion(versionValue));
    dispatch(
      configActions.setOperatingSystemVersionValue(
        `${templateValue}:${versionValue}`,
      ),
    );
    dispatch(configActions.setOperatingSystemDistro(distro.id));
    dispatch(configActions.setOperatingSystemTitle(distro.title));
    dispatch(configActions.setOperatingSystemSubTitle(distro.subTitle));
    dispatch(configActions.setTemplateValue(templateValue));
  }

  return (
    <div className="mb-16 3xl:mb-28">
      <div className="flex flex-col xl:flex-row sm:justify-between xl:gap-6">
        <div>
          <h3 className="text-xl font-normal mt-0 mb-2">Image</h3>
          <p className="mb-6">
            Select the operating system image that will be deployed on this
            server.
          </p>
        </div>
        {hasAppTemplates ? (
          <>
            <div className="gap-2 items-center xl:basis-[306px] shrink-0 hidden sm:flex mb-6 xl:mb-0">
              {OS_TABS.map((tab) => (
                <ButtonPill
                  key={tab.id}
                  active={tab.id === configState.operatingSystemTab}
                  onClick={() => handleOperatingSystemTabClick(tab.id)}
                  className="xl:text-base"
                >
                  {tab.name}
                </ButtonPill>
              ))}
            </div>
            <div className="sm:hidden mb-6">
              <Select
                onChange={(event) =>
                  handleOperatingSystemTabClick(event.target.value)
                }
                value={configState.operatingSystemTab}
              >
                {OS_TABS.map((tab) => (
                  <option key={tab.id} value={tab.id}>
                    {tab.name}
                  </option>
                ))}
              </Select>
            </div>
          </>
        ) : null}
      </div>

      <div className="flex flex-col gap-2 lg:gap-4">
        {showSkeleton
          ? Array(4)
            .fill()
            .map((_, index) => (
              <SkeletonCard key={index} className="h-[82px]" />
            ))
          : null}
        {!configState.isLoading &&
          (configState.isError === "" || configState.isError === "api-post") &&
          distros
          ? distros.map((os) => {
            const asset = getTemplateAsset(os?.id);

            return (
              <SelectableCard
                ref={(cardRef.current[os.id] ??= { current: null })}
                key={os.id}
                title={<div className="text-lg">{os.name}</div>}
                subheader={
                  <div className="text-[10px] text-lw-text-disabled">
                    {os.bit}
                  </div>
                }
                value={os.id}
                isSelected={configState.operatingSystemDistro === os.name}
                startElement={
                  asset ? (
                    <img
                      className="max-sm:!w-[30px] max-sm:!h-[30px]"
                      src={asset}
                      alt={`${os.name} Logo`}
                    />
                  ) : null
                }
                endDivider
                endElement={getDistroVersionSelectField(os)}
                onClick={() =>
                  handleDistributionClick(cardRef.current[os.id], os)
                }
              />
            );
          })
          : null}
      </div>
    </div>
  );
}
