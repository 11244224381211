import React, { useCallback, useMemo } from "react";

import { useProductDetails } from "../../../hooks/useProductDetails";
import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import { configurationHelper } from "../../../utils/configurationHelper";
import { Chip } from "../common/chip";
import { SkeletonCard } from "../common/skeleton-card";
import { CYCLE_MAP } from "../constants";
import { SubscriptionCard } from "../subscription-card";

export function SectionSubscription() {
  const [{ configuration: configState }, dispatch] = useAppState();
  const productData = useProductDetails();
  const { status, data } = productData[configState.productCode];
  const { sortByKey } = configurationHelper(data);
  const showSkeleton =
    configState.isLoading || configState.isError === "api-fetch";

  const cycles = useMemo(() => {
    if (status === "success" && data?.cycles) {
      return sortByKey(data.cycles, "months");
    }
    return [];
  }, [status, data, sortByKey]);

  const handleSubscriptionCycle = useCallback(
    (cycle) => {
      const subscriptionDiscount = {
        cycle: cycle.cycle,
        discount: Number(cycle.discount_percent),
        months: cycle.months,
      };
      dispatch(configActions.setSubscriptionCycle(subscriptionDiscount));
    },
    [dispatch],
  );

  if (!configState.hasSubscriptionCycle) {
    return null;
  }

  return (
    <div className="mb-16 3xl:mb-28">
      <h3 className="text-xl font-normal mt-0 mb-6">
        Select Your Subscription
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-2 sm:gap-4">
        {showSkeleton
          ? Array(3)
            .fill()
            .map((_, index) => (
              <SkeletonCard key={index} className="h-[178px] lg:h-[155px]" />
            ))
          : cycles.map((cycle) => (
            <SubscriptionCard
              key={cycle.cycle}
              cycle={cycle}
              title={CYCLE_MAP.get(cycle.cycle)}
              chip={
                Number(cycle.discount_percent) > 0 && (
                  <Chip variant="secondary">{`Save Up To ${Number(cycle.discount_percent)}%`}</Chip>
                )
              }
              isSelected={cycle.cycle === configState.subscriptionCycle.cycle}
              onClick={() => handleSubscriptionCycle(cycle)}
            />
          ))}
      </div>
    </div>
  );
}
