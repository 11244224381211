import classnames from "classnames";
import { find, sortBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useProductDetails } from "../../../hooks/useProductDetails";
import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";
import { configurationHelper } from "../../../utils/configurationHelper";
import { removeDecimalIfWhole } from "../../../utils/removeDecimalIfWhole";
import {
  HARDWARE_CATEGORY_DESCRIPTIONS,
  HARDWARE_CATEGORY_ORDER,
  PRODUCT_TYPES,
  SERVER_TYPE_BARE_METAL,
  SERVER_TYPE_CLOUD_METAL,
  SERVER_TYPE_CLOUD_VPS,
  SERVER_TYPE_GPU,
} from "../constants";

import InfoIcon from "jsx:../../../images/icon-info.inline.svg";
import { ButtonPill } from "../common/button/ButtonPill";
import { Select } from "../common/select";
import { SkeletonCard } from "../common/skeleton-card";
import { HardwareCard } from "../hardware-card";
import { ToolTip } from "../hardware-card/ToolTip";

const images = {
  windows: new URL(
    "../../../images/server-os/windows-icon.png?height=16",
    import.meta.url,
  ),
};

import { IWORX_MIN_MEMORY_GB } from "./SectionControlPanel";

export function SectionHardware() {
  const [showCategoryInfo, setShowCategoryInfo] = useState(false);
  const [showControlPanelNotice, setShowControlPanelNotice] = useState(false);
  const [showWindowsFeeNotice, setShowWindowsFeeNotice] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [{ configuration: state }, dispatch] = useAppState();
  const [searchParams] = useSearchParams();
  const productData = useProductDetails();
  const { status, data } = productData[state.productCode];
  const { getHardwareOptionsByRegion, sortByKey } = configurationHelper(data);
  const isMetalServerType =
    state.serverType === SERVER_TYPE_CLOUD_METAL ||
    state.serverType === SERVER_TYPE_BARE_METAL ||
    state.serverType === SERVER_TYPE_GPU;
  const showSkeleton =
    state.isLoading ||
    state.isError === "api-fetch" ||
    state.isError === "management";

  const hardwareData = useMemo(() => {
    if (status === "success") {
      const hardwareByCategory = [];
      const configs = sortHardware(
        getHardwareOptionsByRegion(state.serverLocation),
      );
      const newHardware =
        data?.hardware &&
        data.hardware.map((level) => ({
          ...level,
          configs: prepareAvailableConfigs(level.configs, configs),
        }));

      // This matches Cloud Metal, Bare Metal and Bare Metal GPU
      if (["bare-metal", "bare-metal-gpu"].includes(state.hardwareTab)) {
        return newHardware;
      }

      HARDWARE_CATEGORY_ORDER.forEach((category) => {
        const match = find(newHardware, { category: category });
        if (match) {
          hardwareByCategory.push(match);
        }
      });

      return hardwareByCategory;
    }

    return [];
  }, [
    status,
    state.productCode,
    state.managementLevel,
    state.serverLocation,
    state.serverType,
  ]);

  useEffect(() => {
    const tab = getInitialTab();

    dispatch(configActions.setHardwareTab(tab));

    if (hardwareData && hardwareData.length > 0) {
      getDefaultHardwareChoice(searchParams.get("config"), tab);
    }
  }, [hardwareData]);

  function getInitialTab() {
    if (searchParams.get("htab")) {
      return searchParams.get("htab");
    }

    if (
      state.serverType === SERVER_TYPE_BARE_METAL ||
      state.serverType === SERVER_TYPE_CLOUD_METAL
    ) {
      return "bare-metal";
    }

    if (state.serverType === SERVER_TYPE_GPU) {
      return "bare-metal-gpu";
    }

    return "gp";
  }

  // If InterWorx is selected but the current config has
  // insufficient RAM to support it, then we automatically
  // select the smallest config that is able to support
  // InterWorx and display a message to the user.
  useEffect(() => {
    const configs = getCurrentHardwareConfigs(state.hardwareTab);
    const activeConfig = find(configs, { id: state.hardwareOption });
    const configsWithMinMemory = configs.filter((val) => Number(val.memory) >= IWORX_MIN_MEMORY_GB);

    if (!activeConfig) return;
    if (configsWithMinMemory.length === 0) return;
    if (state.controlPanel !== "Interworx") return;
    if (Number(activeConfig.memory) >= IWORX_MIN_MEMORY_GB) return;

    const iworxMinConfig =
      configs
        .reduce((acc, val) =>
          Number(val.memory) < Number(acc.memory) ? val : acc,
        ) ?? activeConfig;

    updateHardwareSelection(iworxMinConfig);

    dispatch(configActions.toggleImplicitConfigUpdateNotice(true));

    // CAUTION: Hooks in this component are missing dependencies,
    // but fixing this is not straightforward. We are following the
    // prevailing pattern here for practical reasons, accepting
    // the possibility of subtle bugs until this app is sunset
    // in the near future.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.controlPanel, state.hardwareOption]);

  useEffect(() => {
    setShowWindowsFeeNotice(
      (state.isError === "" || state.isError === "api-post") &&
      state.operatingSystemType === "windows",
    );
    setShowControlPanelNotice(
      state.serverType === SERVER_TYPE_CLOUD_VPS &&
      state.controlPanel !== "NoCP" &&
      state.managementLevel !== "Core-Managed",
    );
    setShowCategoryInfo(
      state.hardwareTab &&
      state.hardwareTab !== "bare-metal" &&
      state.hardwareTab !== "bare-metal-gpu",
    );
  }, [
    state.hardwareTab,
    state.serverType,
    state.managementLevel,
    state.controlPanel,
    state.isError,
    state.operatingSystemType,
  ]);

  function prepareAvailableConfigs(potentialConfigs, availableConfigs) {
    const matchingConfigs = [];

    potentialConfigs.forEach((config) => {
      const match = find(availableConfigs, { id: config.id });
      if (match) {
        matchingConfigs.push(
          Object.assign(config, match, {
            monthlyPrice: getMonthlyCost(match.price),
          }),
        );
      }
    });

    return sortByKey(matchingConfigs, "display_order");
  }

  function getMonthlyCost(priceArray = []) {
    const priceObject = find(priceArray, { unit: "month" });

    return priceObject?.amount ? priceObject.amount : 0;
  }

  function getDisplayCost(hardware) {
    const price = getMonthlyCost(hardware.price);

    const windowsLicenseObject = getWindowsLicenseObject(hardware);
    const windowsLicenseFee = windowsLicenseObject?.price
      ? getMonthlyCost(windowsLicenseObject.price)
      : 0;

    if (state.operatingSystemType === "windows" && windowsLicenseFee) {
      return price
        ? removeDecimalIfWhole(Number(price) + Number(windowsLicenseFee))
        : "";
    }

    return price ? removeDecimalIfWhole(price) : "";
  }

  function getCpuModelValue(model, count = 1) {
    if (!model) {
      return "";
    }

    const DUAL = "Dual";
    const QUAD = "Quad";

    if (count === 2 && !model.trim().startsWith(DUAL)) {
      return `${DUAL} ${model}`;
    }

    if (count === 4 && !model.trim().startsWith(QUAD)) {
      return `${QUAD} ${model}`;
    }

    return model;
  }

  function getNetworkSpeedValue(capacity) {
    if (!capacity) {
      return "";
    }

    if (isNaN(Number(capacity))) {
      return capacity;
    }

    if (Number(capacity) >= 1000) {
      const value = parseFloat(capacity / 1000);
      // Float values are set to one place.
      const formattedValue = !Number.isInteger(value)
        ? value.toFixed(1)
        : value.toString();

      return `${formattedValue} GHz`;
    }

    return `${capacity} MHz`;
  }

  function getDiskValue(capacity, raid) {
    if (!capacity) {
      return "";
    }

    // Use "2x" prefix if raid level equals 1
    const prefix = raid === 1 ? "2x " : "";

    if (Number(capacity) >= 1000) {
      return `${prefix}${parseInt(capacity / 1000).toFixed(1)} TB`;
    }

    return `${prefix}${capacity} GB`;
  }

  function getBandwidthValue(capacity) {
    if (!capacity) {
      return "";
    }

    if (isNaN(Number(capacity))) {
      return capacity;
    }

    if (Number(capacity) >= 1000) {
      const value = parseFloat(capacity / 1000);
      // Float values are set to one place.
      const formattedValue = !Number.isInteger(value)
        ? value.toFixed(1)
        : value.toString();

      return `${formattedValue} TB`;
    }

    return `${capacity} GB`;
  }

  function getDefaultHardwareChoice(configId, tab) {
    const activeHardware = getCurrentHardwareConfigs(tab);
    const categoryName = getSummaryCategoryName(tab);
    const getDefaultBy = configId ? { id: Number(configId) } : { default: 1 };
    const queriedHardware = find(activeHardware, getDefaultBy);
    const defaultHardware = queriedHardware || activeHardware?.[0];

    setConfigs(activeHardware);

    if (defaultHardware) {
      setWindowsAddOns(defaultHardware);

      dispatch(configActions.setHardwareOption(defaultHardware.id));

      dispatch(
        summaryActions.setHardwareCategory({
          title: "",
          value: categoryName,
          cost: getMonthlyCost(defaultHardware.price),
        }),
      );
      dispatch(summaryActions.setHardwareDetails(defaultHardware));
    }
  }

  function getSummaryCategoryName(tab = "gp") {
    const activeCategory = hardwareData.find((o) => o.category === tab);

    if (!isMetalServerType) {
      return activeCategory?.description ? activeCategory.description : "";
    }

    return PRODUCT_TYPES?.[state.serverType]?.name
      ? PRODUCT_TYPES[state.serverType].name
      : "";
  }

  function getCurrentHardwareConfigs(tab = "gp") {
    const hardwareObject = find(hardwareData, (o) => o.category === tab);
    const configs = hardwareObject?.configs ? hardwareObject.configs : [];

    return configs.map((config) => getNormalizedConfig(config));
  }

  function getNormalizedConfig(config) {
    if (!config) {
      return;
    }

    return {
      ...config,
      cpu_model: getCpuModelValue(config.cpu_model, config.cpu_count),
      cpu_speed: getNetworkSpeedValue(config.cpu_speed),
      disk: getDiskValue(config.disk, config.raid_level),
      cores: state.isBareMetal
        ? `${config.cores} / ${config.vcpu}`
        : config.cores,
      bandwidth: getBandwidthValue(config.bandwidth),
    };
  }

  function sortHardware(configs) {
    return sortBy(configs, ["monthlyPrice", "display_order", "id"]);
  }

  function updateHardwareSelection(hardware) {
    setWindowsAddOns(hardware);
    dispatch(configActions.setHardwareOption(hardware.id));

    const activeCategory = hardwareData.find(
      (o) => o.category === state.hardwareTab,
    );

    const categoryName = getSummaryCategoryName(state.hardwareTab);

    dispatch(
      summaryActions.setHardwareCategory({
        title: "",
        value: categoryName,
        cost: getMonthlyCost(hardware.price),
      }),
    );
    dispatch(summaryActions.setHardwareDetails(hardware));
  }

  const handleHardwareTabClick = (tab) => {
    dispatch(configActions.setHardwareTab(tab));
    getDefaultHardwareChoice("", tab);
  };

  const handleHardwareSelectionClick = (hardware) => {
    updateHardwareSelection(hardware);
    dispatch(configActions.toggleImplicitConfigUpdateNotice(false));
  };

  function getWindowsLicenseObject(hardware) {
    const windowsLicense = find(hardware?.included_options, {
      key: "WindowsLicense",
    });

    return windowsLicense?.values?.[0];
  }

  function getMsSqlObject(hardware) {
    const msSQL = find(hardware?.included_options, { key: "MsSQL" });

    return msSQL ? msSQL : [];
  }

  function setWindowsAddOns(hardware) {
    const msSqlObject = getMsSqlObject(hardware);
    const windowsLicenseObject = getWindowsLicenseObject(hardware);
    const windowsLicenseName = windowsLicenseObject?.description
      ? windowsLicenseObject.description
      : "";
    const windowsLicenseFee = windowsLicenseObject?.price
      ? getMonthlyCost(windowsLicenseObject.price)
      : 0;

    dispatch(
      summaryActions.setWindowsLicense({
        value: windowsLicenseName,
        cost: windowsLicenseFee,
      }),
    );
    dispatch(
      configActions.setAvailableMsSql(
        msSqlObject?.values ? msSqlObject.values : [],
      ),
    );
  }

  function getWindowsFeeNotice() {
    return (
      <div className="flex items-center p-3 bg-lw-ui-light text-sm gap-3 rounded">
        <img
          className="grow-0 shrink-0"
          src={images.windows}
          alt="Windows Logo"
        />
        <span>Windows licensing fee is included in your hardware price.</span>
      </div>
    );
  }

  function getControlPanelNotice() {
    const configs = getCurrentHardwareConfigs();
    const activeConfig = find(configs, { id: state.hardwareOption });

    if (activeConfig?.memory && Number(activeConfig.memory) < 4) {
      return (
        <ul className="mt-3 text-sm">
          <li
            className={classnames(
              "relative",
              "pl-3",
              "before:absolute",
              "before:left-0",
              "before:pr-2",
              "before:inline-block",
              'before:content-["•"]',
              "before:align-middle",
            )}
          >{`For the best ${!state?.controlPanel || state.controlPanel === "NoCP" ? "Control Panel" : state.controlPanel} experience, we recommend at least 4 GB of memory.`}</li>
        </ul>
      );
    }
    return null;
  }

  function getHardwareCategoryInfo() {
    return HARDWARE_CATEGORY_DESCRIPTIONS &&
      HARDWARE_CATEGORY_DESCRIPTIONS.get(state.hardwareTab) ? (
      <p>{HARDWARE_CATEGORY_DESCRIPTIONS.get(state.hardwareTab)}</p>
    ) : null;
  }

  const getShouldDisableOption = (hardware) =>
    state.controlPanel === "Interworx" &&
    Number(hardware.memory) < IWORX_MIN_MEMORY_GB;

  return (
    <div className="mb-16 3xl:mb-28">
      <h3 className="text-xl font-normal mt-0 mb-2">Hardware</h3>
      <p className="relative flex mb-6 gap-[10px]">
        Physical components powering servers for data processing and storage.
        {state.serverType !== SERVER_TYPE_CLOUD_VPS ? <ToolTip /> : null}
      </p>

      {state.serverType === SERVER_TYPE_CLOUD_VPS ? (
        <div className="mb-6">
          <div className="gap-2 hidden sm:flex">
            {showSkeleton
              ? Array(3)
                .fill()
                .map((_, index) => (
                  <SkeletonCard
                    key={index}
                    className="h-[42px] !rounded-full grow"
                  />
                ))
              : null}
            {!state.isLoading && !state.isError && hardwareData
              ? hardwareData.map((filter) => (
                <ButtonPill
                  key={filter.category}
                  active={filter.category === state.hardwareTab}
                  onClick={() => handleHardwareTabClick(filter.category)}
                  className="lg:text-sm xl:text-base"
                >
                  {filter.description}
                </ButtonPill>
              ))
              : null}
          </div>

          {showSkeleton ? (
            <SkeletonCard className="h-[50px] sm:hidden" />
          ) : (
            <Select
              controlElemClass="sm:hidden"
              onChange={(event) => handleHardwareTabClick(event.target.value)}
              value={state.hardwareTab}
            >
              {hardwareData
                ? hardwareData.map((filter, index) => (
                  <option key={index} value={filter.category}>
                    {filter.description}
                  </option>
                ))
                : null}
            </Select>
          )}
        </div>
      ) : null}

      {showControlPanelNotice || showWindowsFeeNotice || showCategoryInfo ? (
        <div className="mb-10 flex flex-col gap-3">
          {showControlPanelNotice || showCategoryInfo ? (
            <div className="p-3 bg-lw-ui-light text-sm flex gap-3 rounded">
              <div className="text-black basis-4 shrink-0 grow-0 pt-[2px]">
                <InfoIcon width="16" height="16" />
              </div>
              <div>
                {getHardwareCategoryInfo()}
                {getControlPanelNotice()}
              </div>
            </div>
          ) : null}

          {showWindowsFeeNotice ? getWindowsFeeNotice() : null}
        </div>
      ) : null}

      <div
        id="hardware"
        className={classnames("grid", "gap-2", "sm:gap-4", {
          "grid-cols-2": state.serverType === SERVER_TYPE_CLOUD_VPS,
          "xl:grid-cols-3": state.serverType === SERVER_TYPE_CLOUD_VPS,
          "grid-cols-1": isMetalServerType,
          "sm:grid-cols-2": isMetalServerType,
        })}
      >
        {showSkeleton
          ? Array(state.serverType === SERVER_TYPE_CLOUD_VPS ? 9 : 4)
            .fill()
            .map((_, index) => (
              <SkeletonCard key={index} className="h-[148px]" />
            ))
          : null}
        {!state.isLoading &&
          (state.isError === "" || state.isError === "api-post")
          ? configs.map((hardware) => (
            <HardwareCard
              key={hardware.id}
              serverType={state.serverType}
              isSelected={state.hardwareOption === hardware.id}
              isBareMetal={state.isBareMetal}
              onClick={() => handleHardwareSelectionClick(hardware)}
              data={hardware}
              cost={getDisplayCost(hardware)}
              billingCycle={"Monthly"}
              disabled={getShouldDisableOption(hardware)}
            />
          ))
          : null}
      </div>
    </div>
  );
}
