import { forwardRef } from "react";

import { useAppState } from "../../../../state";
import { SkeletonCard } from "../../common/skeleton-card";
import { SUMMARY_ROUTE_MAP } from "../../constants";

import { removeDecimalIfWhole } from "../../../../utils/removeDecimalIfWhole";

export const HardwareSection = forwardRef(function (props, ref) {
  const [{ configuration: configState, summary: summaryState }] = useAppState();
  const { onClick, isWindows } = props;
  const { hardwareCategory, hardwareDetails, license } = summaryState;

  function getHardwareList() {
    let map = SUMMARY_ROUTE_MAP.get(configState.serverType);

    let list = [];
    map.forEach((item, index) => {
      list.push(
        <div key={index}>
          <span className="text-lw-text-disabled">{`${item.label}: `}</span>
          {`${hardwareDetails[index]} ${item.suffix}`}
        </div>,
      );
    });

    return list;
  }

  return (
    <table
      className="text-sm w-full"
      aria-label="Configuration Hardware Choices"
    >
      <caption>
        <div className="flex justify-between w-full text-xs mb-2">
          <span className="uppercase text-lw-text-disabled tracking-wide">
            Hardware
          </span>
          <button
            aria-label="Edit hardware configuration choices"
            className="text-xs underline text-lw-text-disabled hover:text-lw-text-primary transition-all"
            onClick={() => onClick(ref)}
          >
            Edit
          </button>
        </div>
      </caption>
      <thead className="sr-only">
        <tr>
          <th>Product Choice</th>
          <th>Cost</th>
        </tr>
      </thead>
      <tbody>
        {configState.isLoading || configState.isError === "api-fetch" ? (
          Array(5)
            .fill()
            .map((_, index) => (
              <tr key={index}>
                <td>
                  <SkeletonCard className="w-full h-3 my-[3px] !rounded-sm !bg-lw-ui-border" />
                </td>
              </tr>
            ))
        ) : (
          <tr className="align-top">
            <td>
              <div>{hardwareCategory?.value ? hardwareCategory.value : ""}</div>
              <div className="options">{getHardwareList()}</div>
            </td>
            <td className="text-right">
              {hardwareCategory?.cost ? `$${removeDecimalIfWhole(hardwareCategory.cost)}` : ""}
            </td>
          </tr>
        )}
        {!configState.isLoading &&
        configState.isError !== "api-fetch" &&
        isWindows ? (
          <tr className="align-top">
            <td>
              <span className="text-lw-text-disabled">
                {license?.title ? `${license.title}: ` : ""}
              </span>
              {license?.value ? license.value : ""}
            </td>
            <td className="text-right">
              {license?.cost ? `$${removeDecimalIfWhole(license.cost)}` : ""}
            </td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );

});
