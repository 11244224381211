import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { InView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";

import { useProductDetails } from "../hooks/useProductDetails";
import { useScrollBlock } from "../hooks/useScrollBlock";
import { useAppState } from "../state";
import actions from "../state/configuration/actions";
import scrollToElement from "../utils/scrollToElement";

import {
    CONFIGURATION_SECTIONS,
    CONFIGURATION_SECTIONS_BARE_METAL,
    ERROR_API_FETCH,
    PRODUCT_METAL_LIN,
    PRODUCT_METAL_WIN,
    PRODUCT_ROUTE_MAP,
    PRODUCT_VPS_LIN,
    PRODUCT_VPS_WIN,
    ROOT_MARGIN,
    SCROLL_OFFSET,
    SERVER_TYPE_BARE_METAL,
    SERVER_TYPE_CLOUD_METAL,
    SERVER_TYPE_CLOUD_VPS,
    SERVER_TYPE_GPU,
} from "../components/configuration/constants";
import { FloatingCart } from "../components/configuration/floating-cart";
import { Navigation } from "../components/configuration/navigation";
import {
    SectionBackUps,
    SectionBlockStorage,
    SectionControlPanel,
    SectionHardware,
    SectionIpAddresses,
    SectionManagement,
    SectionOS,
    SectionProtection,
    SectionServerLocation,
    SectionServerType,
    SectionSubscription,
    SectionTemplate,
    SectionWindowsAddOns,
} from "../components/configuration/section";
import { Summary } from "../components/configuration/summary";

const ConfigurationPage = () => {
  const serverTypeRef = useRef();
  const subscriptionRef = useRef();
  const locationRef = useRef();
  const osRef = useRef();
  const managementRef = useRef();
  const imageRef = useRef();
  const templateControlsRef = useRef();
  const addOnsRef = useRef();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("server-type");
  const [summaryPanelOpen, setSummaryPanelOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [{ configuration: state }, dispatch] = useAppState();
  const productData = useProductDetails();
  const { isLoading, isError, data } = productData[state.productCode];
  const serverType = location.pathname.replace(/\//g, "") || SERVER_TYPE_CLOUD_VPS;
  const isWindows = state.operatingSystemType === "windows";
  const isBareMetal = [SERVER_TYPE_BARE_METAL, SERVER_TYPE_GPU].includes(state.serverType);
  const hasCycles = data?.cycles?.length > 1;

  useEffect(() => {
    dispatch(actions.setServerType(serverType));
    dispatch(actions.setIsLoading(isLoading));
    dispatch(actions.setIsError(isError ? "api-fetch" : ""));
    dispatch(actions.setErrorMessage(isError ? ERROR_API_FETCH : ""));
  }, [dispatch, isLoading, isError]);

  useEffect(() => {
    let productCode = PRODUCT_ROUTE_MAP.get(state.serverType);

    if (state.serverType === SERVER_TYPE_CLOUD_METAL) {
      productCode = isWindows ? PRODUCT_METAL_WIN : PRODUCT_METAL_LIN;
    }
    if (state.serverType === SERVER_TYPE_CLOUD_VPS) {
      productCode = isWindows ? PRODUCT_VPS_WIN : PRODUCT_VPS_LIN;
    }

    if (isBareMetal) {
      dispatch(actions.unsetWindowsSettings());
    }

    dispatch(actions.setProductCode(productCode));
    dispatch(actions.setIsBareMetal(isBareMetal));
  }, [dispatch, state.serverType, state.operatingSystemType]);

  useEffect(() => {
    dispatch(actions.setHasSubscriptionCycle(hasCycles));
    dispatch(actions.setPackageVersionId(data?.package_version_id));
  }, [dispatch, data]);

  function handleNavigationClick(id) {
    setActiveSection(id);
    setSummaryPanelOpen(false);
    allowScroll();

    switch (id) {
      case "server-type":
        scrollToElement(serverTypeRef.current, SCROLL_OFFSET);
        return;
      case "subscription":
        scrollToElement(subscriptionRef.current, SCROLL_OFFSET);
        return;
      case "location":
        scrollToElement(locationRef.current, SCROLL_OFFSET);
        return;
      case "os":
        scrollToElement(osRef.current, SCROLL_OFFSET);
        return;
      case "management":
        scrollToElement(managementRef.current, SCROLL_OFFSET);
        return;
      case "image":
        scrollToElement(imageRef.current, SCROLL_OFFSET);
        return;
      case "hardware":
        scrollToElement(templateControlsRef.current, SCROLL_OFFSET);
        return;
      case "add-ons":
        scrollToElement(addOnsRef.current, SCROLL_OFFSET);
        return;
    }
  }

  function handleSectionVisible(isVisible, sectionId) {
    if (isVisible && sectionId) {
      setActiveSection(sectionId);
    }
  }

  function handleSummaryPanelChange(isOpen) {
    setSummaryPanelOpen(isOpen);
    isOpen ? blockScroll() : allowScroll();
  }

  return (
    <div className="font-roobert bg-lw-off-white">
      <Navigation
        activeSection={activeSection}
        sections={
          state.isBareMetal
            ? CONFIGURATION_SECTIONS_BARE_METAL
            : CONFIGURATION_SECTIONS
        }
        onClick={handleNavigationClick}
      />
      <div className="flex flex-col lg:flex-row gap-[60px]">
        <div
          className={classnames(
            "w-full",
            "lg:w-auto",
            "flex-auto",
            "fixed",
            "top-[72px]",
            "z-10",
            "lg:sticky",
            "lg:top-0",
            "self-start",
            "lg:h-screen",
            "bg-lw-ui-light",
            "flex",
            "justify-end",
            "p-0",
            "lg:px-12",
            "lg:pt-[150px]",
            "lg-reduced-height:pt-[115px]",
            "group-[.noscroll]/body:overflow-y-scroll",
            "group-[.noscroll]/body:h-[calc(100dvh-125px)]",
            "group-[.noscroll]/body:lg:!h-screen",
            {
              "lg:pt-[160px]": state.isError,
              "lg-reduced-height:pt-[160px]": state.isError,
            },
          )}
        >
          <div
            className={classnames(
              "mx-auto",
              "w-full",
              "lg:mr-0",
              "lg:ml-auto",
              "lg:w-[360px]",
              "2xl:w-[480px]",
              "3xl:w-[680px]",
            )}
          >
            <Summary
              isSummaryOpen={summaryPanelOpen}
              setSummaryOpen={handleSummaryPanelChange}
              locationRef={locationRef.current}
              serverTypeRef={serverTypeRef.current}
              termRef={subscriptionRef.current}
              templateControlsRef={templateControlsRef.current}
              managementRef={managementRef.current}
              addOnsRef={addOnsRef.current}
            />
          </div>
        </div>
        <div
          className={classnames(
            "flex-auto",
            "col-span-5",
            "px-4",
            "sm:px-12",
            "lg:pl-0",
            "lg-reduced-height:pt-[115px]",
            "py-[150px]",
            {
              "lg:pt-[160px]": state.isError,
              "lg-reduced-height:pt-[160px]": state.isError,
            },
          )}
        >
          <div
            className={classnames(
              "w-full",
              "lg:max-w-[1280px]",
              "mx-auto",
              "lg:mx-0",
              "flex",
              "flex-col",
            )}
          >
            <InView
              rootMargin={ROOT_MARGIN}
              onChange={(inView) => handleSectionVisible(inView, "server-type")}
            >
              <div ref={serverTypeRef}>
                <SectionServerType />
              </div>
            </InView>

            <div className="2xl:max-w-[75%]">
              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "location")}
              >
                <div ref={locationRef}>
                  <SectionServerLocation />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "os")}
              >
                <div ref={osRef}>
                  <SectionOS />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "management")}
              >
                <div ref={managementRef}>
                  <SectionManagement />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "image")}
              >
                <div ref={imageRef}>
                  <SectionTemplate />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) =>
                  handleSectionVisible(inView, "subscription")
                }
              >
                <div ref={subscriptionRef}>
                  <SectionSubscription />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "hardware")}
              >
                <div
                  ref={templateControlsRef}
                  className="flex flex-col gap-16 3xl:gap-28"
                >
                  <SectionControlPanel />
                  <SectionHardware />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "add-ons")}
              >
                <div ref={addOnsRef} className="flex flex-col gap-16 3xl:gap-28">
                  <SectionWindowsAddOns />
                  <SectionBlockStorage />
                  <SectionBackUps />
                  <SectionIpAddresses />
                  <SectionProtection />
                </div>
              </InView>
              </div>

          </div>
        </div>
      </div>
      <FloatingCart />
    </div>
  );
};

export default ConfigurationPage;
