import React, { useEffect, useMemo } from "react";
import find from "lodash/find";

import { configurationHelper } from "../../../utils/configurationHelper";
import { useProductDetails } from "../../../hooks/useProductDetails";
import { useAppState } from "../../../state";
import actions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";
import { SERVER_TYPE_BARE_METAL } from "../constants";

import { CardButtonBase } from "../common/card/CardButtonBase";
import { Chip } from "../common/chip";
import { SkeletonCard } from "../common/skeleton-card";

const DDOS_OPTIONS = [
  {
    id: "standard",
    name: "Standard",
    description: "Protect against Layer 4 Attacks. Volumetric, up to 2 GBps.",
    cost: "",
    costPerMonth: 0,
  },
  {
    id: "advanced",
    name: "Advanced",
    description: "Protect against Layer 4 Attacks. Volumetric, up to 10 GBps.",
    cost: "",
    costPerMonth: 0,
  },
];

export function SectionProtection() {
  const [{ configuration: state }, dispatch] = useAppState();
  const productData = useProductDetails();
  const { status, data } = productData[state.productCode];
  const { getDataByRegion } = configurationHelper(data);
  const showSkeleton =
    state.isLoading ||
    state.isError === "api-fetch" ||
    state.isError === "management";

  const ddosOptions = useMemo(() => {
    if (status === "success") {
      const { ddos } = getDataByRegion(state.serverLocation);

      if (!Array.isArray(ddos) || ddos.length === 0) {
        return [];
      }

      return DDOS_OPTIONS.map((option) => {
        const current = find(ddos, { name: option.name });
        const price = getMonthlyCost(current?.price);

        return Object.assign(option, {
          // TODO: Revisit data structure to validate the default property
          default: current?.default === 1,
          cost: price === 0 ? "No Cost" : `$${price} Monthly`,
          costPerMonth: price,
        });
      });
    }

    return [];
  }, [status, state.serverType, state.serverLocation]);

  useEffect(() => {
    // If no DDoS options, or no options with cost, set config and summary
    // state and return early.
    if (ddosOptions.length === 0 || !ddosOptions.some((option) => option.costPerMonth > 0)) {
      dispatch(actions.setDdosOption(''));
      dispatch(
        summaryActions.setDdos({
          value: '',
          cost: 0,
        }),
      );

      return;
    }

    // Find default option, or use first option in array.
    const defaultOption = ddosOptions.find((option) => option.default) || ddosOptions[0];

    // Set DDoS state to default
    if (defaultOption) {
      dispatch(actions.setDdosOption(defaultOption.name));
      dispatch(
        summaryActions.setDdos({
          value: defaultOption.name,
          cost: defaultOption.costPerMonth,
        }),
      );
    }
  }, [ddosOptions]);

  function getMonthlyCost(priceArray = []) {
    const priceObject = find(priceArray, { unit: "month" });

    return priceObject?.amount ? Number(priceObject.amount) : 0;
  }

  function handleProtectionClick(option) {
    dispatch(
      summaryActions.setDdos({
        value: option.name,
        cost: option.costPerMonth,
      }),
    );
    dispatch(actions.setDdosOption(option.name));
  }

  // Section is hidden if no options, or no options have no `costPerMonth`
  if ((!state.isLoading && ddosOptions.length === 0) || !ddosOptions.some((option) => option.costPerMonth > 0)) {
    return null;
  }

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-2">DDoS Protection</h3>
      <p className="mb-6">
        Distinct tiers of oversight for server operations, offering varying
        degrees of user control and support.
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
        {showSkeleton
          ? Array(2)
              .fill()
              .map((_, index) => (
                <SkeletonCard
                  key={index}
                  className="h-[130px] lg:h-[162px] grow"
                />
              ))
          : null}
        {!state.isLoading &&
        (state.isError === "" || state.isError === "api-post") &&
        ddosOptions
          ? ddosOptions.map((option) => (
              <CardButtonBase
                key={option.id}
                onClick={() => handleProtectionClick(option)}
                isSelected={option.name === state.ddosOption}
              >
                <div className="flex-col items-start">
                  <h4 className="text-lg font-normal mt-0 mb-3">
                    {option.name}
                  </h4>
                  <Chip>{option.cost}</Chip>
                  <div className="mt-4">
                    <span className="text-sm">{option.description}</span>
                  </div>
                </div>
              </CardButtonBase>
            ))
          : null}
      </div>
    </div>
  );
}
